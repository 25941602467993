<template>
  <b-card>
    <h4 class="mb-0">
      Edit Studi Kasus
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="nisn" rules="required">
              <b-form-group label="Siswa" label-for="nisn" :state="errors.length > 0 ? false : null">
                <v-select id="nisn" v-model="dataStudent.nisn" :reduce="(nisn) => nisn.nisn" placeholder="Pilih Siswa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listSiswa" label="name" disabled />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Tanggal" label-for="date">
              <validation-provider #default="{ errors }" name="date" rules="required">
                <b-form-input id="date" v-model="dataStudent.date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Permasalahan" label-for="problem">
              <validation-provider #default="{ errors }" name="problem" rules="required">
                <b-form-input id="problem" v-model="dataStudent.problem" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="background" rules="required">
              <b-form-group label="Latar Belakang" label-for="background" :state="errors.length > 0 ? false : null">
                <v-select id="background" v-model="dataStudent.background" :reduce="(background) => background.real_id"
                  placeholder="Pilih Siswa" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listBackground"
                  label="background_desc" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col><b-col md="6">

            <b-form-group label="Estimasi Permasalahan" label-for="estimated_problem">
              <validation-provider #default="{ errors }" name="estimated_problem" rules="required">
                <b-form-input id="estimated_problem" v-model="dataStudent.estimated_problem"
                  :state="errors.length > 0 ? false : null" placeholder="Estimasi Permasalahan" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Penanganan" label-for="handling">
              <validation-provider #default="{ errors }" name="handling" rules="required">
                <b-form-input id="handling" v-model="dataStudent.handling" :state="errors.length > 0 ? false : null"
                  placeholder="Penanganan" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tindak Lanjut" label-for="follow_up">
              <validation-provider #default="{ errors }" name="follow_up" rules="required">
                <b-form-input id="follow_up" v-model="dataStudent.follow_up" :state="errors.length > 0 ? false : null"
                  placeholder="Tindak Lanjut" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <validation-provider #default="{ errors }" name="bk_teacher">
              <b-form-group label="Guru BK" label-for="bk_teacher" :state="errors.length > 0 ? false : null">
                <v-select id="bk_teacher" v-model="dataStudent.bk_teacher"
                  :reduce="(bk_teacher) => bk_teacher.bk_teacher" placeholder="Pilih Guru"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="ListBK" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>            
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    listSiswa: [],
    ListBK: [],
    ListGuruBK: [],
    listBackground: [],
    name: '',
    dataStudent: {
      nisn: '',
      date: '',
      problem: '',
      background: '',
      estimated_problem: '',
      handling: '',
      follow_up: '',
      bk_teacher: '',
    },
  }),
  computed: {

  },
  created() {
    this.getStudentDetail()
    this.getsiswa()
    this.getGuru()
    this.getBackgorund()
  },
  methods: {
    async getBackgorund() {
      try {
        const response = await this.$http.get('/casesstudiesbackgrounds')
        this.listBackground = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    async getsiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getGuru() {
      try {
        const response = await this.$http.get('/employees')
        this.listGuru = response.data.data;
        this.ListGuruBK = response.data.data;
        for (var i in this.listGuru) {
          var item = this.listGuru[i];
          this.ListBK.push({
            "bk_teacher": item.id,
            "name": item.name,
          });
        }
        console.log(this.ListBK);
      } catch (err) {
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/studentcasestudies/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.name = data.student.name
      this.dataStudent.nisn = data.nisn
      this.dataStudent.date = data.date.substr(0, 10)
      this.dataStudent.problem = data.problem
      this.dataStudent.background = data.background.id
      this.dataStudent.estimated_problem = data.estimated_problem
      this.dataStudent.handling = data.handling
      this.dataStudent.follow_up = data.follow_up
      this.dataStudent.bk_teacher = data.bk_teacher
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            this.$http.post(`/studentcasestudies/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-studi-kasus' })
            // alertnya()
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
